
// 注意：刷新页面会导致页面路由重置
import request from "@/utils/request";

let posting = false;

export const updateMainMemory = async (key) => {
    posting = true;
    await cleanMainMemory(key)
    let value = null
    switch (key){
        case "entitySelectMemory":
            localStorage.setItem("entitySelectTime", new Date().getTime().toString())
            await request.post("/CMDB/entitySelect").then(res => {
                value = res.data
                localStorage.setItem("entitySelectMemory", JSON.stringify(value))
            })
            posting = false
            return value;
        case "getPropertyTreeMemory":
            localStorage.setItem("getPropertyTreeTime", new Date().getTime().toString())
            await request.post("/CMDB/getPropertyTree").then(res => {
                value = res.data
                localStorage.setItem("getPropertyTreeMemory", JSON.stringify(value))
            })
            posting = false
            return value;
        case "":
            break;
    }
}

export const checkMainMemory = async (key) => {
    const memory = localStorage.getItem(key);
    if (memory === undefined || memory === null || memory === "" || memory === "undefined" || memory === "null") {
        return await updateMainMemory(key).then(res => {
            return res
        });
    } else {
        return await checkMainMemoryTime(key).then(res => {
            return res
        });
    }
}

export const checkMainMemoryTime = async (key) => {
    if (!posting){
        return await request.get("/memory/findAll").then(res => {
            let list = res.data
            if (list !== undefined && list !== null) {
                //服务器时间
                let ketItem = list.find(item => item.name+"Memory" === key)
                //本地时间
                let timeKey = localStorage.getItem(ketItem.name+"Time");
                if(timeKey === undefined || timeKey === null || Date.parse(ketItem.time) > timeKey){
                    return updateMainMemory(ketItem.name+"Memory").then(res => {
                        return  res
                    })
                } else {
                    return JSON.parse(localStorage.getItem(key));
                }
            }
        })
    }
}

export const checkAllMainMemory = async () => {
    if (window.location.href.endsWith("/login")){
        return;
    }
    if (!posting){
        request.get("/memory/findAll").then(res => {
            let list = res.data
            if (list !== undefined && list !== null) {
                list.forEach(item => {
                    let timeKey = localStorage.getItem(item.name+"Time");
                    if(timeKey === undefined || timeKey === null || Date.parse(item.time) > timeKey){
                        updateMainMemory(item.name+"Memory")
                    }
                })
            }
        })
    }
}

export const cleanMainMemory = async (key) => {
    localStorage.removeItem(key)
}

export const getMainMemory = async (key) => {
    try{
        // await cleanMainMemory(key)
        return await checkMainMemory(key).then((res)=>{
            // memory = JSON.parse(res)
            // console.log(memory)
            return  res
        })
    } catch (e){
        cleanMainMemory(key).then(()=>{
            getMainMemory(key)
        })
    }
}



